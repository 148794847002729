.ambassador-profile {
  &__title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 32px;
    h3 {
      margin: 0 0 0 0;
      height: 22px;
      color: var(--primary-font-color);
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
    }
    img {
      margin: 0 8px 0 0;
      cursor: pointer;
    }
    &__incomplete-text {
      margin: 0 0 0 0;
      height: 22px;
      color: var(--secondary-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 26px;
      font-weight: 500;
    }
    &--popup {
      h3 {
        margin: 0 5px 20px 0;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 31px;
        text-align: center;
      }
    }
  }
  &__profile-picture {
    label {
      justify-content: center !important;
    }
    &__profile-upload {
      margin-top: 20px !important;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: -15px auto auto 6px;
      }
      .MuiAvatar-root {
        width: 60px !important;
        height: 60px !important;
      }
      &__image-wrapper {
        width: 60px !important;
        min-width: 60px !important;
        overflow: hidden;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        &__image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 100px !important;
        }
        @media (max-width: 767px) {
          margin-right: 0 !important;
        }
      }
      .profile-upload-link {
        margin-top: 0 !important;
        padding-left: 0;
        @media (max-width: 767px) {
          padding-left: 0px;
        }
        .upload-icon {
          margin: 4px 0 0 0;
          @media (max-width: 767px) {
            margin-top: 16px;
          }
        }
      }
    }
  }
  &__phone_number {
    margin: 20px 0 0 0;
    label {
      justify-content: center !important;
    }
    input {
      max-width: 325px !important;
      border: 2px solid var(--gray-outline-color) !important;
    }
  }
  &__summary {
    margin: 20px 0 0 0;
    &__title {
      color: var(--field-label-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
    }
    &__textbox {
      margin-top: 6px;
      width: 50%;
      @media (max-width: 635px) {
        width: 100%;
      }
    }
  }
  &__hobbies {
    margin: 20px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    > div:first-child {
      width: 264px;
      > div {
        margin-bottom: 0px !important;
        input {
          height: 60px !important;
        }
      }
    }
  }
  &__interests {
    margin: 20px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    > div:first-child {
      width: 264px;
      > div {
        margin-bottom: 0px !important;
        input {
          height: 60px !important;
        }
      }
    }
  }
  &__tag {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: flex-end;
    padding-bottom: 5px;
    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 49px;
      max-width: fit-content;
      border-radius: 40px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      padding-inline: 20px;
      span {
        height: 17px;
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
      }
      img {
        width: 15px;
        margin-left: 7px;
        cursor: pointer;
      }
    }
  }
  &__submit {
    display: flex;
    justify-content: center;
    margin: 25px 0 0 0;
  }
  &--popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    .ambassador-profile__summary {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__textbox {
        min-width: 325px !important;
      }
    }
    .ambassador-profile__hobbies {
      margin: 20px 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 13px;
      > div:first-child {
        width: 325px !important;
        margin: 0 0 0 0;
        > div {
          margin-bottom: 0px !important;
        }
        label {
          display: block !important;
          text-align: center !important;
        }
        input {
          text-align: center;
          height: 60px !important;
        }
      }
    }
    .ambassador-profile__interests {
      margin: 10px 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 13px;
      > div:first-child {
        width: 325px !important;
        margin: 0 0 0 0;
        > div {
          margin-bottom: 0px !important;
          label {
            display: block !important;
            text-align: center !important;
          }
          input {
            text-align: center;
            height: 60px !important;
          }
        }
      }
    }
    .ambassador-profile__tag {
      justify-content: center;
    }
  }
  &__summary {
    &__title {
      color: var(--field-label-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
    }
    &__textbox {
      margin-top: 6px;
      width: 50%;
      @media (max-width: 635px) {
        width: 100%;
      }
    }
  }
  &__hobbies {
    margin: 20px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    > div:first-child {
      width: 264px;
      margin: 0 13px 0 0;
      > div {
        margin-bottom: 0px !important;
        input {
          height: 60px !important;
        }
      }
    }
  }
  &__interests {
    margin: 20px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    > div:first-child {
      width: 264px;
      > div {
        margin-bottom: 0px !important;
        input {
          height: 60px !important;
        }
      }
    }
  }
  &__tag {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: flex-end;
    padding-bottom: 5px;
    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 49px;
      max-width: fit-content;
      border-radius: 40px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      padding-inline: 20px;
      span {
        height: 17px;
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
      }
      img {
        width: 15px;
        margin-left: 7px;
        cursor: pointer;
      }
    }
  }
  &__submit {
    display: flex;
    justify-content: center;
    margin: 25px 0 0 0;
  }
  &--popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    .ambassador-profile__summary {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__textbox {
        min-width: 325px !important;
      }
    }
    .ambassador-profile__hobbies {
      margin: 20px 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 13px;
      > div:first-child {
        width: 325px !important;
        margin: 0 0 0 0;
        > div {
          margin-bottom: 0px !important;
        }
        label {
          display: block !important;
          text-align: center !important;
        }
        input {
          text-align: center;
          height: 60px !important;
        }
      }
    }
    .ambassador-profile__interests {
      margin: 10px 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 13px;
      > div:first-child {
        width: 325px !important;
        margin: 0 0 0 0;
        > div {
          margin-bottom: 0px !important;
          label {
            display: block !important;
            text-align: center !important;
          }
          input {
            text-align: center;
            height: 60px !important;
          }
        }
      }
    }
    .ambassador-profile__tag {
      justify-content: center;
    }
  }
}
