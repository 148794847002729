.m-emp-table {
  margin-top: 22px;
  &__header {
    display: flex;
    flex-wrap: wrap;
    padding-inline: 10px;
    &__filter {
      width: 193px;
      &--nationality {
        width: 205px;
      }
    }
    > div {
      &:nth-child(1) {
        display: none;
        @media (max-width: 1180px) {
          width: 100%;
        }
      }
      &:nth-child(2) {
        display: flex;
        > div {
          margin-right: 12px;
        }
        @media (max-width: 1180px) {
          padding: 15px;
          width: 100%;
        }
        > div {
          @media (max-width: 1180px) {
            float: left;
            margin: 0 12px 8px 0;
          }
          @media (max-width: 767px) {
            float: none;
            width: 100%;
            margin: 0 0px 16px 0;
          }
        }
      }
    }
  }
  &__body {
    margin-top: 30px;
  }

  &--widget {
    margin-top: 0;
    table {
      min-width: 880px;
      thead {
        tr {
          th {
            font-size: 15px !important;
            line-height: 17px !important;
            font-family: "TTCommons-Regular" !important;
            padding: 0px 15px 15px !important;
            font-weight: 400 !important;
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 15px !important;
            line-height: 17px !important;
            font-family: "TTCommons-Regular" !important;
            padding: 10px 16px 14px !important;
            font-weight: 400 !important;
            height: 64px;
          }
        }
      }
    }
  }
}
