@import "../../../styles/colors.scss";

.o-hub {
  margin-top: 30px;
  margin-bottom: 80px;
  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    &__title {
      font-family: "TTCommons-DemiBold";
      color: $primary-font-color;
      font-size: 42px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 32px;
      margin: 0 24px 0 0;
      padding: 6px 0px;
    }
    &__info-icon {
      max-width: 20px;
      margin: 0 0 0 12px;
    }
  }
  &__body {
    margin-top: 48px;
  }
}
