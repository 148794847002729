.service-card {
  width: 267px;
  height: 360px;
  position: relative;
  z-index: 1;
  &:hover {
    .service-card__wrapper {
      opacity: 0.6;
    }
    .service-card__favorite,
    .service-card__learn-more {
      visibility: visible;
      opacity: 1;
    }
    > div:first-child > div > div > div {
      box-shadow: var(--card-box-shadow-hover) !important;
    }
  }
  .service-card__wrapper {
    transition: 0.5s ease;
    > div > a,
    > div > div > div {
      height: 370px;
      display: inline-block;
      width: 100%;
      > div {
        height: 100%;
      }
    }
    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__header {
        width: 100%;
        min-height: 25px;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 2;
        &__label {
          height: 25px;
          max-width: 200px;
          display: flex;
          justify-content: flex-start;
          span {
            display: block;
            height: 25px;
            width: fit-content;
            max-width: 100%;
            color: #000c3d;
            background-color: #ffffff;
            border: 2px solid #f1f4f7;
            border-radius: 12.5px;
            padding: 5px 15px;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &__star {
          position: absolute;
          right: 0px;
          top: 2px;
        }
      }
      &__image {
        width: 100%;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        position: relative;
        img {
          display: block;
          object-fit: contain;
          width: 100%;
          max-height: 100%;
        }
      }
      &__title {
        margin-bottom: 10px;
        h4 {
          margin: 0;
          color: #000c3d;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          max-height: 36px;
          overflow-y: hidden;
        }
      }
      &__description {
        height: fit-content;
        max-height: 118px;
        overflow: hidden;
        margin: 0;
        color: #607790;
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        font-weight: 500;
        width: 100%;
        p {
          margin: 0;
        }
        h3 {
          font-weight: 500;
          margin: 0;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
        }
      }
    }
  }
  .service-card__favorite {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s ease;
  }
  .service-card__favorite--active {
    visibility: visible;
    opacity: 1;
    transition: 0.5s ease;
  }
  .service-card__learn-more {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    z-index: 2;
    min-width: 113px;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s ease;
    span,
    a {
      display: block;
      cursor: pointer;
      width: 113px;
      height: 30px;
      background-color: var(--secondary-color);
      color: white;
      font-family: "TTCommons-Regular";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 17px;
      text-align: center;
      padding: 8px 8px 0px;
      border-radius: 100px;
      text-decoration: none;
    }

    &--widget {
      a {
        background-color: var(--widget-theme-color);
      }
    }
  }
  .service-card__showActions {
    visibility: visible;
    opacity: 1;
  }
}
