.message {
  margin-bottom: 29px;
  &__own {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      margin-right: 11px;
      width: 100%;
      &__title {
        margin: 0 10px 5px 0;
        display: flex;
        align-items: center;
        &__you {
          display: block;
          font-family: "TTCommons-Medium";
          color: var(--primary-dark-color);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 30px;
          height: 25px;
          margin-right: 6px;
        }
        &__amb-tag {
          background: rgba(247, 100, 95, 0.09);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 25px;
          width: 115px;
          border-radius: 12.5px;
          margin-right: 6px;
          img {
            margin-right: 4.5px;
          }
          span {
            height: 11px;
            width: 74px;
            color: #a80c06;
            font-family: "TTCommons-Medium";
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
          }
        }
        &__delivery {
          display: block;
          font-family: "TTCommons-Regular";
          color: var(--gray-color);
          font-size: 15px;
          letter-spacing: 0;
          line-height: 31px;
          height: 25px;
        }
      }
      &__item {
        border: 1.5px solid #d1dde5;
        border-radius: 15px;
        padding: 8px 18px 5px 18px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: 78%;
        &__message {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          white-space: pre-line;
          overflow-wrap: anywhere;
        }
        &__no-message-attachment {
          color: #607790;
          font-family: "TTCommons-Regular";
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
        }
        &__attachment {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 5px;
          text-decoration: none;
          cursor: pointer;
          width: 100%;
          span {
            font-family: "TTCommons-Regular";
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #0030b5;
            margin-left: 3px;
            height: 17px;
            border-bottom: 1px solid #0030b5;
            width: fit-content;
            // Text truncate
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          svg {
            margin-top: 3px;
            min-width: 21px;
          }
        }
      }
      &__item__system {
        border: none !important;
        padding: 8px 10px 5px 18px !important;
        .message__own__content__item__message {
          color: #607790 !important;
          font-size: 15px !important;
        }
      }
    }
    &__info {
      &__image {
        border: 1.5px solid #bfced8;
        border-radius: 100%;
        margin-bottom: 3px;
      }
    }
  }

  &__other {
    display: flex;
    align-items: flex-end;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      margin-left: 11px;
      width: 100%;
      &__title {
        margin-left: 7px;
        margin: 0 0 5px 10px;
        display: flex;
        align-items: center;
        &__name {
          display: block;
          font-family: "TTCommons-Medium";
          color: var(--primary-dark-color);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 30px;
          height: 25px;
          margin-right: 6px;
        }
        &__amb-tag {
          background: rgba(247, 100, 95, 0.09);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 25px;
          width: 115px;
          border-radius: 12.5px;
          margin-right: 6px;
          img {
            margin-right: 4.5px;
          }
          span {
            height: 11px;
            width: 74px;
            color: #a80c06;
            font-family: "TTCommons-Medium";
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
          }
        }
        &__delivery {
          display: block;
          font-family: "TTCommons-Regular";
          color: var(--gray-color);
          font-size: 15px;
          letter-spacing: 0;
          line-height: 31px;
          height: 25px;
        }
      }
      &__item {
        background: #ebf1f7;
        border-radius: 15px;
        padding: 8px 18px 5px 18px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 78%;
        &__message {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          white-space: pre-line;
          overflow-wrap: anywhere;
        }
        &__no-message-attachment {
          color: #607790;
          font-family: "TTCommons-Regular";
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
        }
        &__attachment {
          display: flex;
          align-items: center;
          margin-top: 5px;
          text-decoration: none;
          cursor: pointer;
          width: 100%;
          span {
            font-family: "TTCommons-Regular";
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #0030b5;
            margin-left: 3px;
            height: 17px;
            border-bottom: 1px solid #0030b5;
            width: fit-content;
            // Text truncate
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          svg {
            margin-top: 3px;
            min-width: 21px;
          }
        }
      }
      &__item__system {
        background: none !important;
        padding: 8px 18px 5px 10px !important;
        .message__other__content__item__message {
          color: #607790 !important;
          font-size: 15px !important;
        }
      }
    }
    &__info {
      &__image {
        margin-bottom: 3px;
        border: 1.5px solid #bfced8;
        border-radius: 100%;
      }
    }
  }
}
