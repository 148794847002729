.contact-career-center {
  > div {
    > div {
      > div {
        > div {
          padding: 30px 20px !important;
        }
      }
    }
  }
  &__body {
    &__title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      letter-spacing: 0;
      line-height: 21px;
      margin: 0 0 6px 0;
    }
    &__sub-title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 0 5px 0;
    }
    &__detail {
      display: flex;
      gap: 9px;
      margin-top: 10px;
      &__label {
        color: var(--gray-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        margin: 0 0 0 0;
        min-width: none;
        width: 50px;
      }
      &__description {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
        margin: 0 0 0 0;
      }
    }
    &__contact {
      width: fit-content;
      margin-top: 15px;
      a {
        font-size: 18px;
        line-height: 19px;
        color: var(--link-color);
        border-bottom: 2px solid var(--link-color);
        font-family: "TTCommons-Medium";
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}
