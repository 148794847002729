@import "../../../styles/colors.scss";

.a-switch {
  display: flex;

  .a-switch-item {
    -webkit-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    cursor: pointer;
    font-family: "TTCommons-Medium";
    border: 2px solid $color-grey;
    font-size: 22px;
    line-height: 21px;
    height: 40px;
    min-width: 147px;
    text-align: center;
    padding: 10px 16px;
    color: $primary-font-color;
    @media (max-width: 1367px) {
      font-size: 18px;
      height: 32px;
      min-width: 120px;
      padding: 6px 16px 0;
    }
    &:first-child {
      border-radius: 100px 0 0 100px;
      &.a-switch-selected {
        border-right: 2px solid transparent !important;
      }
    }
    &:last-child {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
      &.a-switch-selected {
        border-left: 2px solid transparent !important;
      }
    }
  }

  .a-switch-selected {
    background-color: $primary-font-color;
    color: $color-white !important;
    border: 2px solid $primary-font-color !important;
  }

  &--small {
    .a-switch-item {
      min-width: auto;
      width: -webkit-fill-available;
      font-family: "TTCommons-Regular";
      border: 2px solid $color-grey;
      font-size: 18px;
      line-height: 21px;
      height: 50px;
      padding: 14px 16px;
    }
  }
}
