@import "../../../../src/styles/colors.scss";
@import "../../../../src/styles/typographies.scss";
@import "../../../../src/styles/custom.scss";
.o-final-container {
  &__footer {
    margin: 30px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 35px;
    &__visibility {
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      border-radius: 100px;
      padding: 18px 20px 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      position: relative;
      @media (max-width: 780px) {
        width: 100%;
      }
      &__label {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
      }
      &__info {
        height: 19px;
        position: absolute;
        top: 17px;
        right: -25px;
        @media (max-width: 780px) {
          right: 25px;
        }
      }
    }
    &__preview {
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      border-radius: 100px;
      padding: 18px 20px 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      cursor: pointer;
      text-decoration: none;
      @media (max-width: 780px) {
        width: 100%;
      }
      &__label {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        display: flex;
        align-items: center;
        svg {
          margin-left: 10px;
          margin: 0 0 2px 10px;
        }
      }
    }
    &__accessibility {
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      border-radius: 100px;
      padding: 18px 20px 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      @media (max-width: 780px) {
        width: 100%;
      }
      &__label {
        color: var(--field-label-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
      }
      &__active {
        color: var(--primary-dark-color);
      }
    }
  }
  &__foot-note {
    display: flex;
    margin-top: 15px;
    @media (max-width: 780px) {
      justify-content: center;
    }
    span {
      color: var(--field-label-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
    }
  }
}
